<script>
import axios from 'axios'
import gmap from '@/libs/gmap'

export default {
  name: 'Gmap',

  // eslint-disable-next-line vue/require-prop-types
  props: ['defaultLocation', 'autocomplete'],

  data() {
    return {
      autoCompleteOptions: {
        componentRestrictions: {
          country: 'EGY',
        },
      },

      gmap,
      markers: [],

      latLng: null,
      fullAddress: null,
    }
  },

  watch: {
    defaultLocation(newValue) {
      this.initLocation(newValue)
    },
  },

  mounted() {
    this.initLocation(this.defaultLocation)
  },

  methods: {
    initLocation(location) {
      if (location && Object.keys(location).length !== 0 && this.defaultLocation.latLng) {
        setTimeout(() => {
          const latLng = this.defaultLocation.latLng.split(',')
          this.markers.push({
            position: {
              lat: Number(latLng[0]),
              lng: Number(latLng[1]),
            },
          })

          this.gmap.center.center = {
            lat: Number(latLng[0]),
            lng: Number(latLng[1]),
          }
        }, 200)
      }

      if (location && Object.keys(location).length !== 0 && this.defaultLocation.fullAddress) {
        setTimeout(() => {
          document.getElementById('gmap-autocomplete').value = this.defaultLocation.fullAddress
        }, 200)
      }
    },

    updateAddress(newLocation) {
      this.markers = []
      this.markers.push({
        position: newLocation.geometry.location,
      })

      this.gmap.center.center = this.markers[0].position
      this.gmap.center.zoom = 13
      this.latLng = `${this.markers[0].position.lat()},${this.markers[0].position.lng()}`
      this.fullAddress = document.getElementById('gmap-autocomplete').value

      this.emitLocationChanged()
    },

    geocodeFromMarker(location) {
      const latLng = `${location.latLng.lat()},${location.latLng.lng()}`

      const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng
      }&key=${this.gmap.apiKey}`
      const customAxios = axios.create()
      customAxios.defaults.withCredentials = false
      delete customAxios.defaults.headers.common.Authorization
      customAxios.get(url)
        .then(response => {
          const newLocation = response.data.results[0] || null
          if (newLocation !== null) {
            document.getElementById('gmap-autocomplete').value = newLocation.formatted_address
            this.latLng = latLng
            this.fullAddress = newLocation.formatted_address

            this.emitLocationChanged()
          }
        })
    },

    emitLocationChanged() {
      const location = {
        latLng: this.latLng,
        fullAddress: this.fullAddress,
      }

      this.$emit('locationChanged', location)
    },
  },
}
</script>

<template>
  <div>
    <gmap-autocomplete
      v-if="autocomplete !== false"
      id="gmap-autocomplete"
      class="form-control map-autocomplete-input"
      :options="autoCompleteOptions"
      style="width: 91%"
      @place_changed="updateAddress"
    />

    <gmap-map
      class="mt-2"
      :center="gmap.center.center"
      :zoom="gmap.center.zoom"
      style="width: 100%; height: 200px"
      :options="gmap.options"
    >
      <gmap-marker
        v-for="(m, index) in markers"
        :key="index"
        :position="m.position"
        :draggable="autocomplete"
        @dragend="geocodeFromMarker"
      />
    </gmap-map>
  </div>
</template>
