<script>
/* eslint-disable no-param-reassign */
import Multiselect from 'vue-multiselect'
import lodash from 'lodash'

import {
  required,
} from '@validations'

export default {
  components: {
    Multiselect,
  },

  // eslint-disable-next-line vue/require-prop-types
  props: ['coverages'],

  data() {
    return {
      required,

      form: {},

      areas: [],

      coverage: [],

      coveragesInitDone: false,
    }
  },

  computed: {
    allCities() {
      return this.$store.getters['cities/cities']
    },

    allAreas() {
      return this.$store.getters['cities/areas']
    },
  },

  watch: {
    coverages(newValue) {
      if (this.coveragesInitDone === true) {
        return
      }

      if (newValue.length === 0) {
        return
      }

      let coverages = Array.from(new Set(newValue))

      // group all areas by cirtyId
      const cities = lodash.groupBy(this.allAreas, 'city_id')
      const citiesAndAreas = []

      // format cities be include only IDs like [citId: [areaId,]]
      lodash.each(cities, (areas, cityId) => {
        citiesAndAreas[cityId] = areas.map(area => area.id)
      })

      const result = []
      citiesAndAreas.forEach((areasId, cityId) => {
        // if the coverage has all city's areas
        const hasCity = areasId.every(areaId => coverages.includes(areaId))
        if (hasCity) {
          result.push({
            id: cityId,
            // eslint-disable-next-line radix
            name: `الكل - ${this.getCityById(parseInt(cityId))}`,
          })

          // remove all areas belongs to this city

          coverages = coverages.filter(coverage => areasId.includes(coverage) === false)
          console.log('filtered coverages: ', coverages)
        }
      })

      coverages.forEach(coverage => {
        result.push(this.allAreas.find(area => area.id === coverage))
      })

      this.coverage = result
      this.coveragesInitDone = true
    },
  },

  methods: {
    getCityById(cityId) {
      const index = this.allCities.findIndex(city => city.id === cityId)
      return this.allCities[index].name
    },

    filterAreas() {
      this.areas = [{
        id: this.form.city.id,
        name: `الكل - ${this.form.city.name}`,
        type: 'city',
      }]

      this.areas = this.areas.concat(this.$store.getters['cities/areas'].filter(area => area.city_id === this.form.city.id))
    },

    setCoverage() {
      if (!this.form.area) {
        return
      }

      // TODO: check if added before

      this.coverage.push(this.form.area)

      this.emitCoverageChanged()
    },

    emitCoverageChanged() {
      const coverages = []
      this.coverage.forEach(coverage => {
        if (coverage.name.includes('الكل')) {
          const areas = this.$store.getters['cities/areas'].filter(area => area.city_id === coverage.id)

          areas.forEach(area => {
            coverages.push(area)
          })
        } else {
          coverages.push(coverage)
        }
      })

      this.$emit('coverageChanged', coverages)
    },

    removeFromCoverage(index) {
      this.coverage.splice(index, 1)
      this.emitCoverageChanged()
    },
  },
}
</script>

<template>
  <div class="row">
    <div class="col-md-6">
      <div class="form-group position-relative">
        <label>المحافظة</label>
        <Multiselect
          v-model="form.city"
          name="city"
          placeholder=""
          select-label=""
          deselect-label=""
          selected-label=""
          :multiple="false"
          track-by="id"
          label="name"
          :show-labels="false"
          :options="allCities"
          @input="filterAreas()"
        />
      </div>
    </div>

    <div class="col-md-6">
      <div class="form-group">
        <label>المنطقة</label>
        <Multiselect
          v-model="form.area"
          name="area"
          :show-no-results="true"
          placeholder=""
          select-label=""
          deselect-label=""
          selected-label=""
          :multiple="false"
          track-by="id"
          label="name"
          :options="areas"
          @input="setCoverage()"
        >
          <template slot="noResult">
            لا توجد نتائج مطابقة
          </template>
          <template slot="noOptions">
            لا توجد خيارات
          </template>
        </Multiselect>
      </div>
    </div>

    <div class="col-md-12 d-inline-block">
      <span
        v-for="(item, index) in coverage"
        :key="index"
        class="btn btn-primary btn-sm ml-1 mt-1 pr-0"
      >
        <span class="d-inline-block pr-1 border-right coverage-text">{{ item.name }}</span>

        <span
          class="d-inline-block px-1"
          @click.prevent="removeFromCoverage(index)"
        >&times;</span>
      </span>
    </div>
  </div>
</template>

<style lang="scss">
    .coverage-text {
        padding: 5px !important;
    }
</style>
