<script>
import Gmap from '@/views/components/Gmap.vue'

import {
  required,
} from '@validations'

export default {
  name: 'LocationSelect',

  components: {
    Gmap,
  },

  // eslint-disable-next-line vue/require-prop-types
  props: ['location'],

  data() {
    return {
      required,

      form: {
        areas: null,
        latLng: null,
        fullAddress: null,
      },

      defaultLocation: null,
      formSubmitted: false,
    }
  },

  watch: {
    location(location) {
      if (location) {
        this.setLocation(location)
      }
    },
  },

  created() {

  },

  methods: {
    setLocation(location) {
      if (location) {
        this.defaultLocation = {
          latLng: location.latLng || null,
          fullAddress: location.fullAddress || null,
        }
      }

      console.log(this.defaultLocation)
    },

    locationChanged(newLocation) {
      this.form.latLng = newLocation.latLng
      this.form.fullAddress = newLocation.fullAddress

      this.emitLocationChanged()
    },

    emitLocationChanged() {
      this.$emit('locationChanged', this.form)
    },
  },
}
</script>

<template>
  <div class="">
    <div class="col-lg-12">
      <div class="form-group">
        <label>العنوان على الخريطة</label>
        <span class="text-danger">*</span>
        <Gmap
          :autocomplete="true"
          :default-location="defaultLocation"
          @locationChanged="locationChanged"
        />
        <div class="invalid-feedback">
          من فضلك
          إختير المكان على الخريطة
        </div>
      </div>
    </div>
  </div>
</template>
